<template>
  <CModal :show.sync="show">
    <template #header>
      <h6 class="modal-title">On Hold Until When?</h6>
    </template>
    <v-date-picker v-model="selectedDate" mode="dateTime" is24hr>
      
    </v-date-picker>
    <template #footer>
      <CButton @click="closeModal" color="primary">Cancel</CButton>
      <CButton @click="emitDate" color="success">OK</CButton>
    </template>
  </CModal>
</template>

<script>
import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  name: 'CalendarModal',
  components: {
    'v-cal-picker': DatePicker,
    'v-date-picker': DatePicker
  },
  data() {
    return {
      selectedDate: null
    };
  },
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    handleDateSelect(date) {
      this.selectedDate = date;
    },
    emitDate() {
      this.$emit('dateSelected', this.selectedDate);
      this.closeModal(); // If you have a method to close the modal
    },
    closeModal() {
      this.$emit('update:show', false);
    }
  }
};
</script>
