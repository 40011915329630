<template>
  <GoogleMap :latitude="latitude" :longitude="longitude" />
</template>

<script>
import * as VueGoogleMaps from 'vue2-google-maps'
import Vue from 'vue'
import * as CaseStuff from "@shared_vue/openapi/caseapi";
import GoogleMap from "./GoogleMap.vue";
//
// Vue.use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyAruewTbxyLgrwiDkWc0NJc55QR5GsWvk4',
//     // key: ''
//     // To use the Google Maps JavaScript API, you must register your app project on the Google API Console and get a Google API key which you can add to your app
//     // v: 'OPTIONAL VERSION NUMBER',
//     libraries: 'places', //// If you need to use place input
//   }
// });

export default {
  name: 'MiniMap',
  components: {GoogleMap},
  props: {
    latitude: {
      type: Number,
      required: true
    },
    longitude: {
      type: Number,
      required: true
    }
  },
  data() { //move data to mock API
    return {
      // center: {lat: -29.7857789, lng: 30.7703669},
      infoContent: '',
      infoLink: '',
      infoWindowPos: {
        lat: 0,
        lng: 0
      },
      infoWinOpen: false,
      currentMidx: null,
      // optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      }
    }
  },
  methods: {
    toggleInfoWindow(marker, idx) {
      this.infoWindowPos = marker.position
      this.infoContent = marker.timestamp
      
      // check if its the same marker that was selected if yes toggle
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen
      } else {
        // if different marker set infowindow to open and reset current marker index
        this.currentMidx = idx
        this.infoWinOpen = true
      }
    }
  },
  
}

</script>

<style scoped>

</style>
