<template>
  <div ref="googleMap" style="width: 100%; height: 400px;"></div>
</template>

<script>
export default {
  name: "GoogleMap",
  props: {
    latitude: {
      type: Number,
      required: true
    },
    longitude: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      map: null,
      marker: null
    };
  },
  mounted() {
    this.initMap();
  },
  watch: {
    // Watching for changes in latitude or longitude
    latitude: 'updatePosition',
    longitude: 'updatePosition'
  },
  methods: {
    initMap() {
      const position = { lat: this.latitude, lng: this.longitude };
      this.map = new google.maps.Map(this.$refs.googleMap, {
        center: position,
        zoom: 15
      });
      this.marker = new google.maps.Marker({
        position: position,
        map: this.map
      });
    },
    updatePosition() {
      const position = { lat: this.latitude, lng: this.longitude };

      // Updating map's center
      this.map.setCenter(position);

      // Moving the marker to the new position
      this.marker.setPosition(position);
    }
  }
};
</script>
