import { render, staticRenderFns } from "./ErrorModal.vue?vue&type=template&id=24f58c8a&scoped=true"
import script from "./ErrorModal.vue?vue&type=script&lang=ts"
export * from "./ErrorModal.vue?vue&type=script&lang=ts"
import style0 from "spinkit/spinkit.min.css?vue&type=style&index=0&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24f58c8a",
  null
  
)

export default component.exports