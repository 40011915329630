























import {vxm} from "@/store";
import {Component, Vue, Prop, PropSync} from 'vue-property-decorator';


@Component
export default class ErrorModal extends Vue {
    @Prop(String) readonly title: string | undefined;
    @PropSync('showvar', { type: Boolean }) syncedShow!: boolean;
    private ui = vxm.ui;



}
