









































































import {Component, Vue, Prop, PropSync} from 'vue-property-decorator';
import {AddressDTO, CompanyDTO, ErpcDTO, ProductDTO} from "../../../shared_vue/openapi/caseapi";


@Component
export default class AddAddressModal extends Vue {
  @PropSync('showvar', { type: Boolean }) syncedShow!: boolean;
  @PropSync('dismissed', { type: Boolean }) syncedDismiss!: boolean;

  private addressClicked: boolean = false;
  private field1: string = "";
  private homestreet: string = "";
  private homecity: string = "";
  private homestate: string = "";
  private homecounty: string = "";
  private homestate_abbr: string = "";
  private homezip: string = "";
  private homecountry: string = "";
  private homelatitude: string = "";
  private homelongitude: string = "";
  private dismiss(){ //instead of using events which are a little opaque, I intend to use a watch on these variables
    this.syncedShow=false;
    this.syncedDismiss=true;
  }

  private submitAddress(){
    console.log('submitting address')
    let newAddress = <AddressDTO>{};
    /*
    Id?: number;
    AddressType?: string;
    Name?: string;
    UnitNumber?: string | null;
    ComplexName?: string | null;
    StreetNumber?: string;
    StreetName?: string;
    Suburb?: string;
    City?: string;
    Province?: string;
    Country?: string;
    PostalCode?: string;
    IsPrimary?: boolean;
    Confirmed?: boolean;
     */
    newAddress.city=this.homecity;
    newAddress.suburb=this.homecounty;
    newAddress.country=this.homecountry;
    newAddress.postalCode=this.homezip;
    newAddress.provinceId=this.homestate_abbr;
    
    newAddress.streetName=this.homestreet;
    newAddress.latitude=this.homelatitude.toString();
    newAddress.longitude=this.homelongitude.toString();
    console.log(newAddress);
    this.$emit('addressselected', newAddress);
  }

  private newInput(eventdata:any){
    console.log('newinput')
    console.log(eventdata)
    this.field1=eventdata;
    this.addressClicked = true;
  }

}
